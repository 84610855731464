import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './header.scss';
import React from 'react';
import { InputGroup } from 'reactstrap';
import { fas } from '@fortawesome/free-solid-svg-icons';

const leftSidebarWidth = 248;

const SearchHeader = () => {
  const toggleSidebar = () => {
    if (document.getElementById('left-menu').style.left === '0px') {
      document.getElementById('left-menu').style.left = `-${leftSidebarWidth}px`;
      document.getElementById('app-view-container').style.marginLeft = '0px';
      document.getElementById('navbar-head').style.marginLeft = '0px';
    } else {
      document.getElementById('left-menu').style.left = '0px';
      document.getElementById('app-view-container').style.marginLeft = `${leftSidebarWidth}px`;
      document.getElementById('navbar-head').style.marginLeft = `${leftSidebarWidth}px`;
    }
  };

  return (
    <InputGroup className="header-search">
      <button type="button" className="btn btn-icon btn-toggle-sidebar" onClick={() => toggleSidebar()}>
        <FontAwesomeIcon icon={fas.faBars} />
      </button>
      {/* <Input placeholder="Tìm kiếm" className="header-search-input" /> */}
      {/* <img src="content/images/vuesax/linear/search-normal.svg" /> */}
    </InputGroup>
  );
};

export default SearchHeader;
