export const DATE_FORMAT = {
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE: 'DD/MM/YYYY',
  YEAR_DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss',
  TIME_ONLY: 'HH:mm',
  MONTH_ONLY: 'MM',
};

export const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

export const DEFAULT_MAX_HOUR_WORK = 16;

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 10;

export const TIME_SHEET_DECIMAL_REGEX = /^\d{1,2}(\.\d{0,2})?$/;
export const TIME_SHEET_BULK_DECIMAL_REGEX =
  /^\d{1,2}(\.\d{0,2})?$|^[oO][fF]?[fF]?$|^[pP]?$|^[pP][oO]?$|^[nN][bB]?$|^[vV]?$|^[kK][pP]?$|^[wW][ff]?[hH]?$/i;
export const DEFAULT_DECIMAL_REGEX = /^\d+(\.\d*)?$/;
export const DEFAULT_INTEGER_REGEX = /^[0-9]\d*$/;
export const DEFAULT_NUMBER_REGEX = /^[0-9]*$/;

export const VIETNAMESE_PHONE_NUMBER_REGEX = /(84|0[2|3|5|7|8|9])+([0-9]{8,9})\b/g;

export const FILE_UTIL = `${window.location.protocol}//${window.location.host}/services/masiutility/api/file-attachments`;
