import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';

const accountMenuItemsAuthenticated = () => {
  const dispatch = useAppDispatch();

  return (
    <>
      <MenuItem icon="wrench" to="/account/settings" data-cy="settings">
        <Translate contentKey="global.menu.account.settings">Settings</Translate>
      </MenuItem>
      <MenuItem icon="lock" to="/account/password" data-cy="passwordItem">
        <Translate contentKey="global.menu.account.password">Password</Translate>
      </MenuItem>
      <MenuItem onClick={() => dispatch(logout())} icon="sign-out-alt" to="/login" data-cy="logout">
        <Translate contentKey="global.menu.account.logout">Sign out</Translate>
      </MenuItem>
    </>
  );
};

const accountMenuItems = () => (
  <>
    <MenuItem id="login-item" icon="sign-in-alt" to="/login" data-cy="login">
      <Translate contentKey="global.menu.account.login">Sign in</Translate>
    </MenuItem>
    <MenuItem icon="user-plus" to="/account/register" data-cy="register">
      <Translate contentKey="global.menu.account.register">Register</Translate>
    </MenuItem>
  </>
);

export const AccountMenu = ({ isAuthenticated = false }) => {
  const account = useAppSelector(state => state.authentication.account);

  return (
    <NavDropdown icon="user" name={(account?.lastName || '') + ' ' + account?.firstName} id="account-menu" data-cy="accountMenu">
      {isAuthenticated && accountMenuItemsAuthenticated()}
      {!isAuthenticated && accountMenuItems()}
    </NavDropdown>
  );
};

export default AccountMenu;
