export const PATH = {
  // TIME KEEPING
  TIME_SHEET: '/time-sheet',
  TIME_SHEET_BULK: '/time-sheet/bulk',
  TIME_SHEET_BULK_APPROVAL: '/time-sheet/bulk/approval',
  TIME_SHEET_EXPLANATION: '/time-sheet/explanation',
  TIME_SHEET_EXPLANATION_VIOLATION: '/time-sheet/explanation/:id/violation',
  TIME_SHEET_VIOLATION: '/time-sheet/violation',
  LEAVE_REQUEST: '/leave-request',

  // PRODUCTION
  PRODUCTION: '/production',
  PRODUCTION_STANDARD: '/production/standard',
  PRODUCTION_COMMAND: '/production/command',
  PRODUCTION_PROCESS: '/production/process',
  PRODUCTION_PROCESS_DETAIL: '/production/process/:id/:workItemId',
  PRODUCTION_PROCESS_TEMPLATE: '/production/process/:id/template',

  PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION: '/production/process/:id/:workItemId/machine-operation-monitoring-template', // Biểu mẫu giám sát hoạt động máy
  PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION_UPDATE:
    '/production/process/:id/:workItemId/machine-operation-monitoring-template/:templateId/update', // Cập nhật biểu mẫu giám sát hoạt động máy
  PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION_DETAIL:
    '/production/process/:id/:workItemId/machine-operation-monitoring-template/:templateId', // Chi tiết biểu mẫu giám sát hoạt động máy

  PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH: '/production/process/:id/:workItemId/magnet-mesh-test-template', // Biểu mẫu kiểm tra nam châm và lưới
  PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH_UPDATE: '/production/process/:id/:workItemId/magnet-mesh-test-template/:templateId/update', // Cập nhật biểu mẫu kiểm tra nam châm và lưới
  PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH_DETAIL: '/production/process/:id/:workItemId/magnet-mesh-test-template/:templateId', // Chi tiết biểu mẫu kiểm tra nam châm và lưới

  PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT: '/production/process/:id/:workItemId/material-receipt-monitoring-template', // Biểu mẫu giám sát tiếp nhận nguyên liệu
  PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT_UPDATE:
    '/production/process/:id/:workItemId/material-receipt-monitoring-template/:templateId/update', // Cập nhật biểu mẫu giám sát tiếp nhận nguyên liệu
  PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT_DETAIL:
    '/production/process/:id/:workItemId/material-receipt-monitoring-template/:templateId', // Chi tiết biểu mẫu giám sát tiếp nhận nguyên liệu

  PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING: '/production/process/:id/:workItemId/monitoring-steaming-drying-template', // Biểu mẫu giám sát công đoạn hấp - sấy
  PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING_UPDATE:
    '/production/process/:id/:workItemId/monitoring-steaming-drying-template/:templateId/update',
  PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING_DETAIL:
    '/production/process/:id/:workItemId/monitoring-steaming-drying-template/:templateId',

  PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING: '/production/process/:id/:workItemId/report-mixing-fishmeal-template', // Báo cáo trộn sản phẩm bột cá
  PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING_UPDATE:
    '/production/process/:id/:workItemId/report-mixing-fishmeal-template/:templateId/update',
  PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING_DETAIL: '/production/process/:id/:workItemId/report-mixing-fishmeal-template/:templateId',

  PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING: '/production/process/:id/:workItemId/selecting-adding-additives-template', // Biểu mẫu lựa và bổ sung phụ gia,
  PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING_UPDATE:
    '/production/process/:id/:workItemId/selecting-adding-additives-template/:templateId/update',
  PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING_DETAIL:
    '/production/process/:id/:workItemId/selecting-adding-additives-template/:templateId', // Chi tiết biểu mẫu lựa và bổ sung phụ gia

  PRODUCTION_QUALITY: '/production/quality',
  PRODUCTION_QUALITY_CREATE: '/production/quality/create',
  PRODUCTION_QUALITY_UPDATE: '/production/quality/:id/update',
  PRODUCTION_QUALITY_CANCEL: '/production/quality/:id/cancel',
  PRODUCTION_WORK_CENTERS: '/production/work-centers',
  PRODUCTION_ROUTINGS: '/production/routings',
  PRODUCTION_MAINTENANCE: '/production/maintenance',
  PRODUCTION_PACKAGES: '/production/packages',

  // BUSINESS
  CUSTOMERS: '/customers',
  CUSTOMERS_DISPOSED: '/customers/disposed',
  CUSTOMERS_TRANSFER: '/customers/transfer',
  PRICE_LIST: '/price-list',
  PRICE_LIST_KIM_LONG: '/price-list/kim-long',
  PRICE_LIST_MMS: '/price-list/mms',
  PRICE_LIST_KIM_LONG_UPDATE: '/price-list/kim-long/:id/update',
  PRICE_LIST_MMS_UPDATE: '/price-list/mms/:id/update',
  PRICE_LIST_DETAIL_KIM_LONG: '/price-list/kim-long/:id',
  PRICE_LIST_DETAIL_MMS: '/price-list/mms/:id',
  PRICE_LIST_CREATE_KIM_LONG: '/price-list/:id/kim-long',
  PRICE_LIST_UPDATE_KIM_LONG: '/price-list/:id/kim-long/:detailId',
  PRICE_LIST_CREATE_MMS: '/price-list/:id/mms',
  PRICE_LIST_UPDATE_MMS: '/price-list/:id/mms/:detailId',
  CONTRACTS: '/contracts',
  CONTRACTS_DELETED: '/contracts/deleted',
  ORDERS: '/orders',
  PURCHASE: '/purchase',

  // HUMAN RESOURCES
  EMPLOYEES: '/employees',
  EMPLOYEES_CREATE: '/employees/create',
  EMPLOYEES_UPDATE: '/employees/:id/update',
  EMPLOYEES_CHANGE_LOGS: '/employees/:id/change-logs',
  ANNUAL_LEAVE: '/annual-leave',
  OFFICES: '/offices',
  LEAVE_REGISTER: '/leave-register',
  RECRUITMENT: '/recruitment',
  RECRUITMENT_CANDIDATES: '/recruitment/candidates',
  DOCUMENTARY: '/documentary',
  UNIFORM: '/uniform',
  UNIFORM_ORDERS: '/uniform/orders',
  UNIFORM_EXPORTS: '/uniform/exports',
  REPORT: '/report',
  REPORT_UNIFORMS_EXPIRED: '/report/uniforms-expired',
  REPORT_UNIFORMS_EXPORTS: '/report/uniforms-exports',
  REPORT_UNIFORMS_REIMBURSEMENT: '/report/uniforms-reimbursement',
  REPORT_EMPLOYEE_EXPIRING_CONTRACT: '/report/employee-expiring-contract',
  REPORT_LEAVE_REGIME: '/report/leave-regime',
  REPORT_RECRUITMENT: '/report/recruitment',
  REPORT_HUMAN_RESOURCE_CHANGE: '/report/human-resource-change',

  // SETTINGS
  UOM: '/uom',
  UOM_GROUP: '/uom/groups',
  UOM_GROUP_DETAIL: '/uom/groups/:id',
  UNIFORM_SETTINGS: '/uniform/settings',
};
