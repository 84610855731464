import './menus-left.scss';
import React from 'react';
import { Nav } from 'reactstrap';
import { Brand } from '../header/header-components';
import MenuItem from '../menu-item/menu-item';
import { ISubMenuItem } from '../menu-item/sub-menu-item';
import { useToggleMenu } from 'app/hooks/use-menu';
import Flex from 'app/components/flex/flex';

export type Menu = {
  key: string;
  icon: React.ReactNode;
  selectedIcon: React.ReactNode;
  label: React.ReactNode;
  items: ISubMenuItem[];
};

interface IMenuProps {
  defaultSelectedKeys?: string[];
  items?: Menu[];
}

const MenuLeft = (props: IMenuProps) => {
  const { items, defaultSelectedKeys } = props;

  const { toggleSelectedKeys, selectedKeys } = useToggleMenu(defaultSelectedKeys);

  return (
    <Nav id="menus-left" vertical>
      <Brand />

      <Flex direction="column" gap={12} className="menus-left-container">
        {items?.map(item => (
          <MenuItem
            onClick={() => toggleSelectedKeys(item.key)}
            key={item.key}
            id={item.key}
            icon={selectedKeys.includes(item.key) ? item.selectedIcon : item.icon}
            items={item.items}
            label={item.label}
            selectedKeys={selectedKeys}
          />
        ))}
      </Flex>
    </Nav>
  );
};

export default MenuLeft;
