import { PATH } from 'app/constants/path';
import { Menu } from 'app/shared/layout/menus-left/menus-left';
import React from 'react';
import { useState } from 'react';

export const useMenu = (): Menu[] => {
  const items = [
    {
      key: 'time-sheet',
      icon: <img src="content/images/vuesax/bulk/task-square.svg" alt="time-sheet" />,
      selectedIcon: <img src="content/images/vuesax/bulk/task-square-selected.svg" alt="time-sheet" />,
      label: 'Bảng chấm công',
      items: [
        {
          label: 'Chấm công',
          to: PATH.TIME_SHEET,
          key: PATH.TIME_SHEET,
        },
        {
          label: 'Vi phạm chấm công',
          key: 'violation',
          items: [
            {
              label: 'Vi phạm chấm công',
              to: PATH.TIME_SHEET_VIOLATION,
              key: PATH.TIME_SHEET_VIOLATION,
            },
            {
              label: 'Giải trình chấm công',
              to: PATH.TIME_SHEET_EXPLANATION,
              key: PATH.TIME_SHEET_EXPLANATION,
            },
          ],
        },
        {
          label: 'Duyệt chấm công tháng',
          to: PATH.TIME_SHEET_BULK_APPROVAL,
          key: PATH.TIME_SHEET_BULK_APPROVAL,
        },
      ],
    },
    {
      key: 'day-off',
      icon: <img src="content/images/vuesax/bulk/document-text.svg" alt="day-off" />,
      selectedIcon: <img src="content/images/vuesax/bulk/document-text-selected.svg" alt="day-off" />,
      label: 'Nghỉ phép',
      to: PATH.LEAVE_REQUEST,
      items: [
        {
          label: 'Danh sách đơn nghỉ phép',
          to: PATH.LEAVE_REQUEST,
          key: PATH.LEAVE_REQUEST,
        },
      ],
    },
    {
      key: 'production',
      icon: <img src="content/images/vuesax/bulk/building.svg" alt="production" />,
      selectedIcon: <img src="content/images/vuesax/bulk/building-selected.svg" alt="production" />,
      label: 'Sản xuất',
      items: [
        {
          label: 'Quản lý lệnh',
          to: PATH.PRODUCTION_COMMAND,
          key: PATH.PRODUCTION_COMMAND,
        },
        {
          label: 'Quản lý công đoạn sản xuất',
          to: PATH.PRODUCTION_PROCESS,
          key: PATH.PRODUCTION_PROCESS,
        },
        {
          label: 'Quản lý kiểm tra chất lượng',
          to: PATH.PRODUCTION_QUALITY,
          key: PATH.PRODUCTION_QUALITY,
        },
        {
          label: 'Quản lý đóng gói',
          to: PATH.PRODUCTION_PACKAGES,
          key: PATH.PRODUCTION_PACKAGES,
        },
        {
          label: 'Quản lý tuyến kho sản xuất',
          to: PATH.PRODUCTION_ROUTINGS,
          key: PATH.PRODUCTION_ROUTINGS,
        },
        {
          label: 'Quản lý bảo trì sản phẩm',
          to: PATH.PRODUCTION_MAINTENANCE,
          key: PATH.PRODUCTION_MAINTENANCE,
        },
        {
          label: 'Kế hoạch định mức',
          to: PATH.PRODUCTION_STANDARD,
          key: PATH.PRODUCTION_STANDARD,
        },
        {
          label: 'Quản lý cụm máy sản xuất',
          to: PATH.PRODUCTION_WORK_CENTERS,
          key: PATH.PRODUCTION_WORK_CENTERS,
        },
      ],
    },
    {
      key: 'customers',
      icon: <img src="content/images/vuesax/bulk/building.svg" alt="production" />,
      selectedIcon: <img src="content/images/vuesax/bulk/building-selected.svg" alt="production" />,
      label: 'Kinh doanh',
      items: [
        {
          label: 'Quản lý khách hàng',
          key: 'customers-management',
          items: [
            {
              label: 'Danh sách khách hàng',
              to: PATH.CUSTOMERS,
              key: PATH.CUSTOMERS,
            },
            {
              label: 'Danh sách vô hiệu hoá',
              to: PATH.CUSTOMERS_DISPOSED,
              key: PATH.CUSTOMERS_DISPOSED,
            },
          ],
        },
        {
          label: 'Bảng báo giá',
          key: 'priceList',
          to: PATH.PRICE_LIST,
        },
        {
          label: 'Hợp đồng',
          key: 'contracts-management',
          items: [
            {
              label: 'Danh sách tất cả hợp đồng',
              to: PATH.CONTRACTS,
              key: PATH.CONTRACTS,
            },
            {
              label: 'Danh sách hợp đồng đã xoá',
              to: PATH.CONTRACTS_DELETED,
              key: PATH.CONTRACTS_DELETED,
            },
          ],
        },
        {
          label: 'Quản lý đơn đặt hàng',
          key: 'orders-management',
          items: [
            {
              label: 'Đơn đặt hàng',
              to: PATH.ORDERS,
              key: PATH.ORDERS,
            },
          ],
        },
        {
          label: 'Đề nghị thu mua',
          key: 'purchase',
          to: PATH.PURCHASE,
        },
      ],
    },
    {
      key: 'employees',
      icon: <img src="content/images/vuesax/bulk/user.svg" style={{ width: 20, height: 20 }} alt="user" />,
      selectedIcon: <img src="content/images/vuesax/bulk/user-selected.svg" alt="user-selected" />,
      label: 'HCNS',
      items: [
        {
          label: 'Hồ sơ nhân viên',
          key: 'employeesProfile',
          items: [
            {
              label: 'Danh sách NV',
              to: PATH.EMPLOYEES,
              key: PATH.EMPLOYEES,
            },
            {
              label: 'Văn phòng/Nhà máy',
              to: PATH.OFFICES,
              key: PATH.OFFICES,
            },
          ],
        },
        {
          label: 'Đăng ký nghỉ chế độ',
          key: 'leaveRegister',
          to: PATH.LEAVE_REGISTER,
        },
        {
          label: 'Phép năm',
          key: 'annualLeave',
          to: PATH.ANNUAL_LEAVE,
        },
        {
          label: 'Tuyển dụng',
          key: 'recruitment',
          items: [
            {
              label: 'Yêu cầu tuyển dụng',
              to: PATH.RECRUITMENT,
              key: PATH.RECRUITMENT,
            },
            {
              label: 'Danh sách ứng viên',
              to: PATH.RECRUITMENT_CANDIDATES,
              key: PATH.RECRUITMENT_CANDIDATES,
            },
          ],
        },
        {
          label: 'Công văn',
          key: 'documentary',
          to: PATH.DOCUMENTARY,
        },
        {
          label: 'Đồng phục',
          key: 'uniform',
          items: [
            {
              label: 'Danh sách đồng phục',
              to: PATH.UNIFORM,
              key: PATH.UNIFORM,
            },
            {
              label: 'Đơn mua đồng phục',
              to: PATH.UNIFORM_ORDERS,
              key: PATH.UNIFORM_ORDERS,
            },
            {
              label: 'Quản lý kho',
              to: PATH.UNIFORM_EXPORTS,
              key: PATH.UNIFORM_EXPORTS,
            },
          ],
        },
        {
          label: 'Báo cáo',
          key: 'report',
          items: [
            {
              label: 'Nhân viên đến hạn cấp đồng phục',
              to: PATH.REPORT_UNIFORMS_EXPIRED,
              key: PATH.REPORT_UNIFORMS_EXPIRED,
            },
            {
              label: 'Nhập xuất tồn đồng phục',
              to: PATH.REPORT_UNIFORMS_EXPORTS,
              key: PATH.REPORT_UNIFORMS_EXPORTS,
            },
            {
              label: 'NV sắp hết hạn HĐ',
              to: PATH.REPORT_EMPLOYEE_EXPIRING_CONTRACT,
              key: PATH.REPORT_EMPLOYEE_EXPIRING_CONTRACT,
            },
            {
              label: 'Biến động nhân sự',
              to: PATH.REPORT_HUMAN_RESOURCE_CHANGE,
              key: PATH.REPORT_HUMAN_RESOURCE_CHANGE,
            },
            {
              label: 'Số lượng NV đăng ký nghỉ chế độ',
              to: PATH.REPORT_LEAVE_REGIME,
              key: PATH.REPORT_LEAVE_REGIME,
            },
            {
              label: 'Yêu cầu tuyển dụng',
              to: PATH.REPORT_RECRUITMENT,
              key: PATH.REPORT_RECRUITMENT,
            },
          ],
        },
      ],
    },
    {
      key: 'settings',
      icon: <img src="content/images/vuesax/bulk/setting.svg" alt="setting" style={{ width: 20, height: 20 }} />,
      selectedIcon: <img src="content/images/vuesax/bulk/setting-selected.svg" alt="user-selected" />,
      label: 'Cài đặt',
      items: [
        {
          label: 'Quản lý đơn vị',
          key: 'uom-settings',
          items: [
            {
              label: 'Danh sách đơn vị',
              to: PATH.UOM,
              key: PATH.UOM,
            },
            // {
            //   label: 'Nhóm đơn vị',
            //   to: PATH.UOM_GROUP,
            //   key: PATH.UOM_GROUP,
            // },
          ],
        },
        {
          label: 'Đồng phục',
          key: 'uniform-settings',
          to: PATH.UNIFORM_SETTINGS,
        },
      ],
    },
  ];

  return items;
};

export const useToggleMenu = (defaultSelectedKeys: string[]) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>(defaultSelectedKeys ?? []);

  const toggleSelectedKeys = (key: string) => {
    if (selectedKeys.includes(key)) {
      setSelectedKeys(prev => prev.filter(item => item !== key));
    } else {
      setSelectedKeys(prev => [...prev, key]);
    }
  };

  return { toggleSelectedKeys, selectedKeys, setSelectedKeys };
};
