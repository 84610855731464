import React from 'react';

interface IFlex extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  align?: 'start' | 'center' | 'end' | 'stretch';
  justify?: 'start' | 'center' | 'end' | 'space-between' | 'space-around' | 'space-evenly';
  gap?: number;
  children: React.ReactNode;
  alignSelf?: 'start' | 'center' | 'end' | 'stretch';
  flexWrap?: 'wrap';
}

const Flex = (props: IFlex) => {
  const { direction = 'row', align, justify, gap, children, style, alignSelf, flexWrap, ...rest } = props;
  return (
    <div
      {...rest}
      style={{
        display: 'flex',
        flexWrap,
        flexDirection: direction,
        alignItems: align,
        justifyContent: justify,
        alignSelf,
        gap: `${gap}px`,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;
