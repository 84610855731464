import Loadable from 'react-loadable';
import React from 'react';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Route } from 'react-router';
import { PATH } from 'app/constants/path';

const loading = <div>loading ...</div>;

const ProductionProcess = Loadable({
  loader: () => import(/* webpackChunkName: "production-process" */ 'app/modules/production-process/production-process'),
  loading: () => loading,
});

const ProductionCommand = Loadable({
  loader: () => import(/* webpackChunkName: "production-command" */ 'app/modules/production-command/production-command'),
  loading: () => loading,
});

const ProductionProcessDetail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "production-process-detail" */ 'app/modules/production-process-detail/production-process-detail'),
  loading: () => loading,
});

const ProductionProcessTemplate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "production-process-template" */ 'app/modules/production-process-template/production-process-template'),
  loading: () => loading,
});

const ProductionQuality = Loadable({
  loader: () => import(/* webpackChunkName: "production-quality" */ 'app/modules/production-quality/production-quality'),
  loading: () => loading,
});

const CancelTestingTemplate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "cancel-testing-template" */ 'app/modules/production-quality/components/cancel-testing-template'),
  loading: () => loading,
});

const ProductionQualityCreate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "production-quality-create" */ 'app/modules/production-quality/components/production-quality-create'),
  loading: () => loading,
});

const ProductionQualityUpdate = Loadable({
  loader: () =>
    import(/* webpackChunkName: "production-quality-update" */ 'app/modules/production-quality/components/production-quality-update'),
  loading: () => loading,
});

const ProductionStandard = Loadable({
  loader: () => import(/* webpackChunkName: "production-standard" */ 'app/modules/production-standard/production-standard'),
  loading: () => loading,
});

const ProductionWorkCenters = Loadable({
  loader: () => import(/* webpackChunkName: "production-machines" */ 'app/modules/production-work-centers/production-work-centers'),
  loading: () => loading,
});

const ProductionRoutings = Loadable({
  loader: () => import(/* webpackChunkName: "production-routings" */ 'app/modules/production-routings/production-routings'),
  loading: () => loading,
});

const ProductionMaintenance = Loadable({
  loader: () => import(/* webpackChunkName: "production-maintenance" */ 'app/modules/production-maintenance/production-maintenance'),
  loading: () => loading,
});

const ProductionPackages = Loadable({
  loader: () => import(/* webpackChunkName: "production-packages" */ 'app/modules/production-packages/production-packages'),
  loading: () => loading,
});

const ProductionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path={PATH.PRODUCTION}>
      <Route index path={PATH.PRODUCTION_STANDARD} element={<ProductionStandard />} />
      <Route path={PATH.PRODUCTION_PROCESS} element={<ProductionProcess />} />
      <Route path={PATH.PRODUCTION_COMMAND} element={<ProductionCommand />} />
      <Route path={PATH.PRODUCTION_PROCESS_DETAIL}>
        <Route index element={<ProductionProcessDetail />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MACHINE_OPERATION_DETAIL} element={<ProductionProcessTemplate />} />

        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MAGNET_MESH_DETAIL} element={<ProductionProcessTemplate />} />

        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MATERIAL_RECEIPT_DETAIL} element={<ProductionProcessTemplate />} />

        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_MONITORING_STEAMING_DETAIL} element={<ProductionProcessTemplate />} />

        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_REPORT_MIXING_DETAIL} element={<ProductionProcessTemplate />} />

        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING_UPDATE} element={<ProductionProcessTemplate />} />
        <Route path={PATH.PRODUCTION_PROCESS_TEMPLATE_SELECTING_ADDING_DETAIL} element={<ProductionProcessTemplate />} />
      </Route>
      <Route path={PATH.PRODUCTION_QUALITY}>
        <Route index element={<ProductionQuality />} />
        <Route path={PATH.PRODUCTION_QUALITY_CREATE} element={<ProductionQualityCreate />} />
        <Route path={PATH.PRODUCTION_QUALITY_UPDATE} element={<ProductionQualityUpdate />} />
        <Route path={PATH.PRODUCTION_QUALITY_CANCEL} element={<CancelTestingTemplate />} />
      </Route>

      <Route path={PATH.PRODUCTION_WORK_CENTERS} element={<ProductionWorkCenters />} />
      <Route path={PATH.PRODUCTION_ROUTINGS} element={<ProductionRoutings />} />
      <Route path={PATH.PRODUCTION_MAINTENANCE} element={<ProductionMaintenance />} />
      <Route path={PATH.PRODUCTION_PACKAGES} element={<ProductionPackages />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductionRoutes;
