import dayjs from 'dayjs';

import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { DATE_FORMAT } from 'app/constants/common';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = (date?: string): dayjs.Dayjs | null => (date ? dayjs(date) : null);

export const displayDefaultDateTime = () => dayjs().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export function getAllDatesInMonth(year: number, month: number): { dayOfWeek: string; dateNumber: number; fullDate: string }[] {
  const startDate = new Date(year, month - 1, 1); // Month is zero-based, so subtract 1
  const endDate = new Date(year, month, 0); // Get the last day of the month

  const dates: { dayOfWeek: string; dateNumber: number; fullDate: string }[] = [];
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    const dateNumber = date.getDate();
    const dayOfWeek = getDayOfWeek(date);
    dates.push({ dayOfWeek, dateNumber, fullDate: dayjs(date).format(DATE_FORMAT.YEAR_DATE) });
  }
  return dates;
}

function getDayOfWeek(date: Date): string {
  const days: string[] = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
  return days[date.getDay()];
}

export interface DayInfo {
  day: string;
  date: number;
  fullDate: string;
}

export function getAllDaysInWeek(currentDate: dayjs.Dayjs): DayInfo[] {
  const startOfWeek = currentDate.startOf('week');
  const endOfWeek = currentDate.endOf('week');

  const daysInWeek: DayInfo[] = [];
  let currentDay = startOfWeek;

  const vietnameseDays: Record<string, string> = {
    Sunday: 'CN',
    Monday: 'T2',
    Tuesday: 'T3',
    Wednesday: 'T4',
    Thursday: 'T5',
    Friday: 'T6',
    Saturday: 'T7',
  };

  while (currentDay.isBefore(endOfWeek) || currentDay.isSame(endOfWeek, 'day')) {
    const dayOfWeek = currentDay.format('dddd');
    const vietnameseDay = vietnameseDays[dayOfWeek];
    daysInWeek.push({ day: vietnameseDay, date: currentDay.date(), fullDate: currentDay.format(DATE_FORMAT.YEAR_DATE) });
    currentDay = currentDay.add(1, 'day');
  }

  return daysInWeek;
}

export function getStartAndEndOfWeek(date: string | Date, format?: string): { startOfWeek: string; endOfWeek: string } {
  const startOfWeek = dayjs(date)
    .startOf('week')
    .format(format || DATE_FORMAT.DATE);
  const endOfWeek = dayjs(date)
    .endOf('week')
    .format(format || DATE_FORMAT.DATE);

  return { startOfWeek, endOfWeek };
}

export function getStartAndEndOfMonth(date: string | Date, format?: string): { startOfMonth: string; endOfMonth: string } {
  const startOfMonth = dayjs(date)
    .startOf('month')
    .format(format || DATE_FORMAT.DATE);
  const endOfMonth = dayjs(date)
    .endOf('month')
    .format(format || DATE_FORMAT.DATE);

  return { startOfMonth, endOfMonth };
}
