import { AUTHORITIES } from 'app/config/constants';
import EntitiesRoutes from 'app/entities/routes';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import Register from 'app/modules/account/register/register';
import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import React from 'react';
import Loadable from 'react-loadable';
import { Navigate, Route } from 'react-router-dom';
import { PATH } from './constants/path';
import RegisterEmail from './modules/account/register/register-email';
import TimeSheetRoutes from './entities/time-sheet';
import LeaveRequestRoutes from './entities/leave-request';
import ProductionRoutes from './entities/production';
import EmployeesRoutes from './entities/employees';
import CustomersRoutes from './entities/customers';
import PriceListRoutes from './entities/price-list';
import ContractsRoutes from './entities/contracts';
import OrdersRoutes from './entities/orders';
import PurchaseRoutes from './entities/purchase';
import AnnualLeaveRoutes from './entities/annual-leave';
import OfficesRoutes from './entities/offices';
import LeaveRegisterRoutes from './entities/leave-register';
import RecruitmentRoutes from './entities/recruitment';
import DocumentaryRoutes from './entities/documentary';
import UniformRoutes from './entities/uniform';
import ReportRoutes from './entities/reports';
import UomRoutes from './entities/uom';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route index element={<Navigate to={PATH.TIME_SHEET} />} />

        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />

        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
              <TimeSheetRoutes />
              <LeaveRequestRoutes />
              <ProductionRoutes />
              <CustomersRoutes />
              <PriceListRoutes />
              <ContractsRoutes />
              <OrdersRoutes />
              <PurchaseRoutes />
              <EmployeesRoutes />
              <AnnualLeaveRoutes />
              <OfficesRoutes />
              <LeaveRegisterRoutes />
              <RecruitmentRoutes />
              <DocumentaryRoutes />
              <UniformRoutes />
              <ReportRoutes />
              <UomRoutes />
            </PrivateRoute>
          }
        />

        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register/email" element={<RegisterEmail />} />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />

        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
