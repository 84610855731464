import { PATH } from 'app/constants/path';
import useNotification from 'app/hooks/use-notification';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DateObject } from 'react-multi-date-picker';
import Badge from 'app/components/badge/badge';
import { INotificationEntity, NotificationAction } from 'app/shared/model/notification.model';
import { IQuotation } from 'app/shared/model/quotation.model';
import Flex from 'app/components/flex/flex';

const { useGetCountUnreadNotifications, useReadNotificationMutation, useMyNotificationsQuery } = useNotification;

const NotificationHeader = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({ page: 0, size: 10 });
  const [dataNotifications, setDataNotifications] = useState([]);

  const { data } = useMyNotificationsQuery(filter);
  const { data: countUnread } = useGetCountUnreadNotifications();
  const { mutate: readNotification } = useReadNotificationMutation();

  const menuRef = React.useRef<any>(null);

  const handleNavigate = (
    id: string,
    action: NotificationAction,
    entityId: string,
    entity: INotificationEntity,
    fromDate?: string,
    toDate?: string,
  ) => {
    readNotification(id);
    setDataNotifications(dataNotifications.map(item => (item.id === id ? { ...item, read: true } : item)));

    if (action === 'BirthdayNotification') {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const filteredMonth = new DateObject(toDate).month.number;

      if (currentMonth === filteredMonth) currentDate.setUTCDate(1);
      const birthdayFrom: string = currentMonth === filteredMonth ? currentDate.toISOString().split('T')[0] : fromDate;
      navigate(`${PATH.CUSTOMERS}?birthdayFrom=${birthdayFrom}&birthdayTo=${toDate}`);
    }

    if (action === 'RecruitmentRequestUpdated') {
      navigate(`${PATH.RECRUITMENT}?id=${entityId}`);
    }

    if (action === 'INTERNAL_APPROVED_QUOTATION') {
      const { id, company } = entity as IQuotation;
      if (company === 'KIM_LONG') {
        navigate(`${PATH.PRICE_LIST_DETAIL_KIM_LONG}`?.replace(':id', id));
      } else {
        navigate(`${PATH.PRICE_LIST_DETAIL_MMS}`?.replace(':id', id));
      }
    }
  };

  const isFetchMore = filter?.page * filter?.size < data?.totalRecord;

  const handleFetchNextPage = () => {
    if (isFetchMore) {
      setFilter({ ...filter, page: filter.page + 1 });
    }
  };

  useEffect(() => {
    if (data) {
      setDataNotifications(prev => [...prev, ...data?.data]);
    }
  }, [data]);

  return (
    <UncontrolledDropdown nav inNavbar id={'notification-dropdown'}>
      <Badge color="error" dot={false} className="position-absolute pointer" children={countUnread} />
      <DropdownToggle nav className="d-flex align-items-center account">
        <img src="content/images/vuesax/bulk/notification.svg" className="notification-icon" alt="notification" />
      </DropdownToggle>
      <DropdownMenu end>
        <div ref={menuRef} className="d-flex flex-column notification-style">
          <h4 style={{ padding: '0 10px' }}>Thông báo</h4>
          {dataNotifications?.map(item => (
            <Flex align="center" justify="space-between" className={`notification-item-wrapper ${item?.read ? 'read' : 'unread'}`}>
              <div
                className={`notification-item`}
                key={item?.id}
                onClick={() =>
                  handleNavigate(
                    item?.id,
                    item?.notification?.data?.action,
                    item?.notification?.entityId,
                    item?.notification?.data?.entity,
                    item?.notification?.data?.fromDate,
                    item?.notification?.data?.toDate,
                  )
                }
              >
                <span className="mr-2">
                  <i>{item?.notification?.title}</i>
                </span>
                <div>{item?.notification?.content}</div>
              </div>
              {!item?.read && <div className="dot dot-primary" />}
            </Flex>
          ))}
          {isFetchMore && (
            <div className="notification-load-more" onClick={handleFetchNextPage}>
              Tải thêm
            </div>
          )}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationHeader;
