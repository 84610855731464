import './menu-item.scss';
import React from 'react';
import { Dropdown } from 'reactstrap';
import SubMenuItem, { ISubMenuItem } from './sub-menu-item';

export interface IMenuItem {
  id: string;
  label: React.ReactNode;
  icon: React.ReactNode;
  items?: ISubMenuItem[];
  onClick?: () => void;
  selectedKeys?: string[];
}

export const ChevronDown = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return <img onClick={onClick} className={className} src="content/images/vuesax/bulk/chevron-down.svg" alt="down" />;
};

export const ChevronUp = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return <img onClick={onClick} className={className} src="content/images/vuesax/bulk/chevron-up.svg" alt="up" />;
};

const MenuItem = (props: IMenuItem) => {
  const { id, label, icon, selectedKeys, items, onClick } = props;

  const selected = selectedKeys?.includes(id);

  return (
    <div className="menu-parent" onClick={onClick}>
      {/* MENU ITEMS */}
      <Dropdown className={`menu-item ${selected ? 'selected' : ''}`}>
        <div className="menu-item-wrapper">
          {icon}
          {label}
        </div>

        {items.length && <>{selectedKeys.includes(id) ? <ChevronUp /> : <ChevronDown />} </>}
      </Dropdown>

      {/* DROPDOWN SUBMENU ITEMS */}
      <div className={`sub-menu ${selected ? 'sub-menu-selected' : ''}`}>
        {items?.map(item => <SubMenuItem key={item.key} id={item.key} to={item.to} label={item.label} items={item.items} />)}
      </div>
    </div>
  );
};

export default MenuItem;
